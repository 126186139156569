page.helpers = {



    getElements: function(){

        page.wrap = document.querySelector(".page-wrap");
        page.screen = document.querySelector(".page__screen");
        page.default = document.querySelector(".default");
        page.header = document.querySelector(".v-header");
        page.logo = document.querySelector(".v-header__logo");
        page.logoBack = document.querySelector(".v-header__logo_back");
        page.menuButton = document.querySelector("#v-menu-button");
        page.footer = document.querySelector(".v-footer");
        page.scroll = document.querySelector("#v-scrollbar");

        return true;

    },



    defaultJS: function(){



        // room id

        let roomAttribute = page.default.getAttribute("data-room-id");
        if(roomAttribute == null){
            page.roomID = null;
        }
        else{
            page.roomID = parseInt(roomAttribute);
        }



        // scrollbar

        let scrollbar = new v.Scrollbar({
            console: page.console
        });
        scrollbar.init();



        // define scroll selector

        page.helpers.scrollSelector();



        // view

        page.view = new v.View({
            console: page.console,
            scrollSelector: page.scrollSelector,
            percent: .9,
            init: false
        });
        page.view = page.view.init();
        
        if(page.view){
            page.view.add({
                target: 'in',
                do: function(data){
                    if(data.in & data.element.classList.contains("view__border")){
                        page.helpers.showBorder(".page__border", data.element);
                    }
                }
            });
        }
    
        function changeView(){
            if(page.view){
                page.view.changeProp({
                    scrollSelector: page.scrollSelector
                });
            }
        }
    
        v.addResize(['w', function(){
            changeView();
        }.bind(this), 250]);



        // parallax

        page.parallax = new v.Parallax({
            console: page.console,
            scrollSelector: page.scrollSelector,
            selector: '.v-parallax_el',
            k: .3
        });
        page.parallax = page.parallax.init();
    
        // resize parallax
    
        function changeParallax(){
            if(page.parallax){
                page.parallax.changeProp({
                    scrollSelector: page.scrollSelector
                });
            }
        }
    
        v.addResize(['w', function(){
            changeParallax();
        }.bind(this), 250]);



        // back button

        let backAttribure = page.default.getAttribute("data-back");
        if(backAttribure !== null){
            if(page.logoBack !== null){
                page.logoBack.setAttribute("href", backAttribure);
                $(page.logoBack).stop().fadeIn(250);
            }
            if(page.menuButton !== null){
                page.menuButton.classList.add("hide");
            }
        }
        else{
            if(page.logoBack !== null){
                $(page.logoBack).stop().fadeOut(250);
            }
            if(page.menuButton !== null){
                page.menuButton.classList.remove("hide");
            }
        }



        // images

        let imagesSlider = document.querySelector('#page__images');
        if(imagesSlider !== null){

            let imagesSliderSlick = function(){

                let imagesSliderAttribute = imagesSlider.getAttribute("data-v-slick-proceeded");

                if(v.vw()[1] > 550){
                    if(imagesSliderAttribute == null){
                        $('#page__images').slick({
                            dots: false,
                            arrows: true,
                            infinite: true,
                            speed: 500,
                            fade: true,
                            cssEase: 'linear',
                            draggable: false,
                            swipe: false
                        });
                        imagesSlider.setAttribute("data-v-slick-proceeded", "true");
                    }
                }
                else{
                    if(imagesSliderAttribute !== null){
                        $('#page__images').slick('unslick');
                        imagesSlider.removeAttribute("data-v-slick-proceeded");
                    }
                }

            };

            let gallery = [];
            $('#page__images a[data-fancybox="room_gallery"]').each(function (i) {
                gallery.push({src: this.href});
                $(this).on({
                    click: function (event) {
                        event.preventDefault();
                        $.fancybox.open(gallery, {loop: false}, i);
                    }
                });
            });
    
            imagesSliderSlick();
            setTimeout(function(){
                page[page.current.name()].addResizeEvent(imagesSliderSlick.bind(this));
            }.bind(this), 650);

        }


        
        return true;

    },



    scrollSelector: function(){

        if(typeof page.scrollSelector != "undefined"){
            return;
        }

        page.scrollSelector = '#v-scrollbar';

        function changeScrollSelector(){
            if(v.mobile()){
                page.scrollSelector = 'html';
            }
            else{
                page.scrollSelector = '#v-scrollbar';
            }
        }
        changeScrollSelector();
    
        v.addResize(['w', function(){
            changeScrollSelector();
        }.bind(this), 125]);

        return true;

    },



    showBorder: function(selector = ".page__border", element = null){

        if(element === null){

            let items = document.querySelectorAll(selector);
            for(let i = 0; i < items.length; i++){
                items[i].classList.add("show");
            }

        }
        else{

            let items = element.querySelectorAll(selector);
            for(let i = 0; i < items.length; i++){
                items[i].classList.add("show");
            }

        }

        return true;

    },



    customButtons: function(){

        let items = document.querySelectorAll(".a-button_custom");
        for(let i = 0; i < items.length; i++){

            let attribute = items[i].getAttribute("data-customButton-proceeded");
            if(attribute == null){

                let html = items[i].innerHTML;

                let leftDiv = document.createElement("div");
                leftDiv.classList.add("a-button__left");
                leftDiv.innerHTML = `<div class="a-button__additional"><span>${html}</span></div>`;
                items[i].appendChild(leftDiv);

                let rightDiv = document.createElement("div");
                rightDiv.classList.add("a-button__right");
                rightDiv.innerHTML = `<div class="a-button__additional"><span>${html}</span></div>`;
                items[i].appendChild(rightDiv);

                page.helpers.customButtonsResize(items[i]);
                if(typeof page.current.addResizeEvent != 'undefined'){
                    page.current.addResizeEvent(page.helpers.customButtonsResize.bind(this, items[i]));
                }

                items[i].setAttribute("data-customButton-proceeded", "true");

            }

        }

        return true;

    },

    customButtonsResize: function(button = null){

        if(button != null){

            let additional = button.querySelectorAll(".a-button__additional");
            for(let i = 0; i < additional.length; i++){

                additional[i].style.width = button.clientWidth + 'px';

                if(additional[i].parentNode.classList.contains("a-button__left")){
                    additional[i].style.right = (button.clientWidth / -2) + 'px';
                }

                if(additional[i].parentNode.classList.contains("a-button__right")){
                    additional[i].style.left = (button.clientWidth / -2) + 'px';
                }

            }

        }

        return true;

    },



    tooltips: function(){

        let items = document.querySelectorAll(".v-tooltip__link");
        for(let i = 0; i < items.length; i++){

            let attribute = items[i].getAttribute("data-tooltip-proceeded");
            if(attribute == null){

                let tooltip = items[i].nextElementSibling;
                if(!tooltip.classList.contains("v-tooltip")){
                    continue;
                }

                items[i].addEventListener("click", function(l, t, e){

                    e.preventDefault();

                    let tooltipAttribute = t.getAttribute("data-tooltip-shown");
                    if(tooltipAttribute === null){

                        let left = $(l).position().left + ($(l).outerWidth(true) - $(l).outerWidth()) + ($(l).outerWidth() / 2);
                        t.style.left = left + 'px';

                        let bottom = l.parentNode.clientHeight - $(l).position().top + 5;
                        t.style.bottom = bottom + 'px';

                        t.classList.add("display_block");
                        t.setAttribute("data-tooltip-shown", "true");
                        setTimeout(() => {
                            let tA = t.getAttribute("data-tooltip-shown");
                            if(tA !== null){
                                t.classList.add("animate");
                            }
                        }, 25);

                    }
                    else{

                        t.classList.remove("animate");
                        t.removeAttribute("data-tooltip-shown");
                        setTimeout(() => {
                            let tA = t.getAttribute("data-tooltip-shown");
                            if(tA === null){
                                t.classList.remove("display_block");
                            }
                        }, 275);

                    }

                }.bind(this, items[i], tooltip));

                window.addEventListener("click", function(l, t, e){

                    if(!v.childOf(e.target, l) & !v.childOf(e.target, t)){

                        t.classList.remove("animate");
                        t.removeAttribute("data-tooltip-shown");
                        setTimeout(() => {
                            let tA = t.getAttribute("data-tooltip-shown");
                            if(tA === null){
                                t.classList.remove("display_block");
                            }
                        }, 275);

                    }

                }.bind(this, items[i], tooltip));            

                items[i].setAttribute("data-tooltip-proceeded", "true");

            }

        }

        return true;

    },



    ajaxContent: function(obj = {}, prop = {}){

        let properties = Object.assign({
            console: false,
            updateUrl: true,
            updateContent: false
        }, prop);

        let loading = false;
        let disabled = false;

        // ajax

        let ajaxContent = new v.AjaxContent(properties);

        // button more

        let moreButton = document.querySelector("#v-ajaxContentMore");

        ajaxContent.add({
            target: 'empty',
            do: function(data){
                if(moreButton !== null){
                    moreButton.classList.add("display_none");
                }
            }.bind(this)
        });

        // init

        ajaxContent = ajaxContent.init();
        if(!ajaxContent){
            return false;
        }

        if(moreButton !== null){
            let moreButtonButton = moreButton.querySelector("a");
            if(moreButtonButton !== null){
                moreButtonButton.addEventListener("click", function(e){
                    e.preventDefault();
                    ajaxContent.next();
                }.bind(this));
            }
        }
    
        // load

        ajaxContent.add({
            target: 'load',
            do: function(data){

                // insert 
                
                let html = document.createElement("html");
                html.innerHTML = data.html;

                let items = html.querySelectorAll(".v-ajaxContent__item");
                for(let i = 0; i < items.length; i++){
                    data.outer.appendChild(items[i]);
                }

                // events

                if(obj.ajaxContentEvent){
                    obj.ajaxContentEvent();
                }

                if(page.defaultPage.view){
                    page.defaultPage.view.changeProp();
                    page.defaultPage.view.seek();
                }

                page.preloader.setEvents();
                if(page.ajax){
                    page.ajax.getElements();
                    page.ajax.setEvents();
                }

                setTimeout(() => {
                    loading = false;
                    checkForScrollLoad();
                }, 500);
    
            }.bind(this)
        });
    
        // last

        ajaxContent.add({
            target: 'last',
            do: function(data){
                if(moreButton !== null){
                    moreButton.classList.add("display_none");
                }
            }.bind(this)
        });

        // scroll event

        let ajaxContentOuter = document.querySelector(ajaxContent.prop().selector);

        function checkForScrollLoad(){

            if(loading || disabled){
                return false;
            }

            let horizontal = false,
                attribute = ajaxContentOuter.getAttribute("data-v-ajaxContent-h");
            if(attribute !== null){
                horizontal = true;
            }
            if(horizontal & v.mobile()){
                horizontal = false;
            }

            if(horizontal){
                if(page.scroll.scrollLeft >= (page.scroll.scrollWidth - v.vw()[1] * 2.5)){
                    loading = true;
                    ajaxContent.next();
                }
            }
            else{
                if(ajaxContentOuter.getBoundingClientRect().bottom < v.vh()[1] * 2.5){
                    loading = true;
                    ajaxContent.next();
                }
            }

            return true;

        }

        window.addEventListener("scroll", checkForScrollLoad.bind(this));
        page.scroll.addEventListener("scroll", checkForScrollLoad.bind(this));

        return {
            ajaxContent: ajaxContent,
            disable: function(){
                disabled = true;
            }.bind(this),
            enable: function(){
                disabled = false;
            }.bind(this)
        };

    },



};